<template>
  <div
    v-if="historicalPriceState.show || followPrice"
    class="price-discount-intro"
    :style="compStyle"
  >
    <img
      v-if="historicalPriceState.show && historicalPriceState.icon"
      :src="historicalPriceState.icon"
      class="price-discount-icon"
    />
    <span>{{ text }}</span>
  </div>
</template>

<script>
/**
 * @file 价格优势说明标签组件
 */

import { defineComponent, computed, onMounted } from 'vue'
import { hitHistoricalPrice } from '../../js/utils.js'
export default defineComponent({
  name: 'PriceDiscountIntro',
  props: {
    setAttrForAnalysis: {
      type: Function,
      default: function() {},
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    salePrice: {
      type: Object,
      default: () => null,
    },
    retailPrice: {
      type: Object,
      default: () => ({
        usdAmount: '0',
      }),
    },
    historicalPrice: {
      type: Array,
      default: () => [],
    },
    followPrice: {
      type: Object,
      default: () => null,
    },
    fontStyle: {
      type: Object,
      default: () => {
        return {
          background: '',
          color: ''
        }
      }
    }
  },
  setup (props) {
    const historicalPriceState = computed(() => {
      if (!props.historicalPrice?.length) {
        return { show: false }
      }

      return hitHistoricalPrice({
        salePrice: props.salePrice,
        retailPrice: props.retailPrice,
        historicalPrice: props.historicalPrice,
      })
    })

    const compStyle = computed(() => {
      const { show, ...historicalData } = historicalPriceState.value
      if (show) {
        return {
          backgroundColor: props.fontStyle?.background || historicalData.backgroundColor,
          color: props.fontStyle?.color || historicalData.fontColor,
        }
      }
      const config = props.followPrice
      if (config) {
        return {
          backgroundColor: props.fontStyle?.background || config.backgroundColor,
          color: props.fontStyle?.color || config.fontColor,
        }
      }
      return null
    })

    const text = computed(() => {
      const { show: showHistoricalPrice, ...historicalData } = historicalPriceState.value
      if (showHistoricalPrice) {
        return historicalData.labelLang || historicalData.labelLangEn
      }
      if (props.followPrice) {
        const config = props.followPrice
        return config.labelLang || config.labelLangEn
      }
      return ''
    })

    onMounted(() => {
      startSetAna({
        historicalPriceState,
        setAttrForAnalysis: props.setAttrForAnalysis,
        followPrice: props.followPrice,
      })
    })

    return {
      compStyle,
      text,
      historicalPriceState,
    }
  },
})

const startSetAna = ({ historicalPriceState, setAttrForAnalysis, followPrice }) => {
  const { show: showHistoricalPrice, priceData = {} } = historicalPriceState.value
  if (showHistoricalPrice) {
    setAttrForAnalysis((el) => {
      if (historicalPriceState.value?.appTraceInfo) {
        el.setAttribute('data-historical-price', historicalPriceState.value.appTraceInfo)
      } else {
        const { high, low, adjust } = priceData
        const tagIds = [high.tagId, low.tagId, adjust.tagId]
        const labelLangEn = historicalPriceState.value.labelLangEn || ''
        el.setAttribute('data-historical-price', `show_lowest_price_${tagIds.join('_')}_${labelLangEn}`)
      }
    })
  } else if (followPrice) {

    setAttrForAnalysis((el) => {
      if (followPrice.appTraceInfo) {
        el.setAttribute('data-follow-price', followPrice.appTraceInfo)
      } else {
        const type = Number(followPrice.tagId) === 3598 ? 1 : 2
        const labelLangEn = followPrice.labelLangEn
        const anaValue =  `show_reduction_save_Price_${type}_${followPrice.tagId}_${labelLangEn}`
        el.setAttribute('data-follow-price', anaValue)
      }
    })
  }
}
</script>

<style lang="less">
.price-discount-intro {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 3.2rem;

  .price-discount-icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
}
</style>
