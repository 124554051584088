<script lang="jsx">
export default {
  name: 'DiscountLabel',
  functional: true,
  inject: {
    constantData: {
      default: ()=>({})
    }
  },
  props: {
    type: {
      type: String,
      default: 'default'
    },
    // 折扣信息
    discountInfo: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    // 是否romwe
    isRW: {
      type: Boolean,
      default: false
    },
    // 是否ar站翻转  -50% 需要转成  %50-
    cssRight: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    }
  },
  render(h, { props, data, slots, injections }) {
    const { constantData } = injections || {}

    let unitDiscountString = props.discountInfo.unitDiscountString

    if (!unitDiscountString) return null
    
    // 反转
    if (constantData?.GB_cssRight || props?.cssRight) {
      unitDiscountString = unitDiscountString.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
    }
    
    return (
      <div
        role="contentinfo"
        class={[
          'product-card__discount notranslate',
          data.class,
          data.staticClass,
          { 'discount_default': props.type === 'default' },
          { 'discount_promo': props.type === 'promo' },
          { 'discount_flash': props.type === 'flash' },
          { 'discount_flash-romwe': !!props.isRW },
          { 'discount_discount': props.type === 'discount' },
          { 'discount__small-size': props.multiColumn },
          props.color || 'origin',
        ]}
        style={[data.style, data.staticStyle]}
      >
        { slots().default }
        { unitDiscountString }
      </div>
    )
  },
}
</script>

<style lang="less" scoped>
.product-card {
  &__discount {
    .font-dpr(20px);
    text-align: center;
    direction: ltr /* rtl: ignore */;
    line-height: 0.4267rem;
    padding: 0 0.1067rem;
    color: #fff;

    // ar 站 rtl 只针对 ar 语言，一旦 英文与阿拉伯文混排，new 标签会加入英文的方向，导致显示问题
    unicode-bidi: normal /* rtl: plaintext */;

    .sui_icon_flashsale_15px {
      .font-dpr(30px);
      display: block;
      margin-bottom: .04rem;
    }
    &.discount {
      &_default {
        background-color: @sui_color_main;
      }
      &_promo {
        background: @sui_color_promo;
      }
      &_flash {
        background: @sui_color_flash;
        color: #222;
      }
      &_flash-romwe {
        color: #fff;
      }
      &_discount {
        height: 0.3733rem;
        line-height: 0.3467rem;
        padding: 0 0.0533rem;
        border: 1px solid @sui_color_discount;
        border-radius: 2px;
        color: @sui_color_discount;
      }
      &__small-size {
        .font-dpr(18px);
        line-height: 0.32rem;
      }
    }

    &.brown {
      border-color: @sui_color_club_rosegold_dark1;
      color: @sui_color_club_rosegold_dark1;  
    }
    &.origin {
      border-color: @sui_color_discount;
      color: @sui_color_discount;
    }
    &.darkGold {
      border-color: #806208;
      color: #806208;
    }
  }
}
</style>
