<script lang="jsx">
import ProductCardSellingPropositionSlideItem from './ProductCardSellingPropositionSlideItem.vue'
export default {
  name: 'ProductCardAutoWrap',
  functional: true,
  components: {
    ProductCardSellingPropositionSlideItem
  },
  render(h, { props, slots }) {
    const { rows = [], rowHeight = 0, highLightRow, isMounted } = props

    const transformAutoWrap = - highLightRow * rowHeight

    const getSlideItemStyle = (index) => {
      if (isMounted) {
        return {}
      }

      return { visibility: index <= 2 ? 'visible' : 'hidden' }
    }

    return (
      <div
        class="product-card__over-hidden"
        style={{ '--transform-auto-wrap': `translateY(${transformAutoWrap}rem)`, '--auto-wrap-height': `${rowHeight}rem` }}
      >
        <div
          ref="autoWrapRef"
          class="product-card__auto-wrap">
          {
            rows.map((item, index) => {

              // 强制换行
              if (item.type === 'br') {
                return <div class="product-card__auto-break placeholder" data-type="br" />
              }

              // 添加 | 分割标签
              if (item.type === 'line') {
                { /* return <div class="product-card__auto-line placeholder" /> */ }
                return null
              }

              if (item.type === 'slot') {
                let tagIndex = item?.labelIndex + 1
                return (
                  <div class="product-card__auto-rank" 
                    data-type="ranking" 
                    data-tag-index={tagIndex}
                  >
                    <ProductCardSellingPropositionSlideItem 
                      itemData={item} 
                      showAverage={false} 
                      showIcon={true} 
                      style={getSlideItemStyle(index)}
                    >
                      { slots().default }
                    </ProductCardSellingPropositionSlideItem>
                  </div>
                )
              }

              // 首屏占位的第2个标签特殊处理 ｜ 线
              if (item.type !== 'gap') {

                // 埋点数据
                let tagId = ''
                let tagIndex = item?.labelIndex + 1
                let tagContent = ''
                let appTraceInfo = ''
                
                { /* isCccxHorseLabel: 是cccx配置的赛马标签，才报这个埋点 */ }
                if (item.type === 'text' && item.isCccxHorseLabel) {
                  tagId = item?.textData?.tagId
                  tagContent = item?.textData?.labelLangEn?.replace(/\s/g, '_')
                  appTraceInfo = item?.textData?.appTraceInfo || ''
                  
                } else if (item.type === 'star') {
                  tagId = item?.starData?.tagId
                  tagContent = ['star', item?.starData?.comment_rank_average || '', item?.starData?.comment_num || ''].join('_')
                  appTraceInfo = item?.starData?.appTraceInfo || ''
                }

                return (
                  <div
                    class={[ 
                      'product-card__auto-label',
                      {
                        'product-card__auto-label_horse': item.isCccxHorseLabel
                      }
                    ]}
                    data-type="label"
                    data-tag-id={tagId}
                    data-tag-index={tagIndex}
                    data-tag-content={tagContent}
                    data-tag-appTraceInfo={appTraceInfo}
                  >
                    {
                      rows[index - 1] && rows[index - 1].type === 'gap' ? (
                        <div class="product-card__auto-gap" />
                      ) : isMounted ? (
                        <div class="product-card__auto-line" />
                      ) : <div class="product-card__auto-empty" />
                    }
                    <ProductCardSellingPropositionSlideItem itemData={item} showAverage={false} showIcon={true} style={getSlideItemStyle(index)}>
                      { slots().default }
                    </ProductCardSellingPropositionSlideItem>
                  </div>
                )
              }

            })
          }
        </div>
      </div>
    )
  }
}

</script>

<style lang="less">
.product-card__over-hidden {
  --single-star-margin-right: 1px;
  // --single-star-font-weight: 600;

  --gap-line-width: 4px;
  --hack-margin: 8px;
  --hack-fill: var(--hack-margin);
  --negative-hack-margin: calc(var(--hack-margin) * -1);

  height: var(--auto-wrap-height);
  overflow: hidden; //隐藏多余的节点，只展示轮播中的滚动行
  width: calc(100% + var(--hack-fill));
  margin-left: var(--negative-hack-margin);
}

.product-card__auto-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transform: var(--transform-auto-wrap, translateY(0));

  .product-card__auto-break {
    flex-basis: 100%;
  }

  .product-card__auto-line {
    border-left: 1px solid #D9D9D9;
    width: 0;
    height: 10px;
    margin: 0 var(--gap-line-width);
  }

  .product-card__auto-gap {
    border-left: 1px solid #D9D9D9;
    width: 0;
    height: 10px;
    margin: 0 var(--gap-line-width);
  }

  .product-card__auto-empty {
    border-left: 1px solid transparent;
    width: 0;
    height: 10px;
    margin: 0 var(--gap-line-width);
  }

  .product-card__auto-rank {
    height: var(--auto-wrap-height);
    line-height: var(--auto-wrap-height);
    width: 100%;
    margin-left: var(--hack-margin);
    padding-right: 4px;
  }

  .product-card__auto-label {
    display: flex;
    align-items: center;
    height: var(--auto-wrap-height);
    line-height: var(--auto-wrap-height);
    font-size: 11px;
    overflow: hidden;
  }
}
</style>
