<template>
  <div 
    ref="feedContent"
    class="product-card__feed"
  >
    <div 
      class="product-card__feed-close"
      :class="{oneline: isOneLine}"
    >
      <i 
        class="suiiconfont sui_icon_nav_close_24px"
        @click.stop="close(false)"
      ></i>
    </div>
    <div 
      v-if="list.length > 0"
      ref="feedList"
      :class="{
        'product-card__feed-list': !isOneLine,
        'product-card__feed-onelist': isOneLine,
        'show': showText,
        'scroll': (list.length > 3 && similarShow) || list.length == 5,
        'similar': similarShow && !more && !isSquareImg,
        'onecolumn': list.length < 3,
        'onescroll': list.length >= 5,
        'square': isSquareImg && more,
        'squareScroll': isSquareImg && !more,
      }"
      :style="{'overflow-y': more && !isOneLine ? 'hidden' : 'scroll'}"
    >
      <div 
        v-for="(item, index) in list"
        :key="item.id"
        ref="feedLi"
        class="product-card__feed-li"
        @click.stop="clickFeedLi(index, item.id)"
      >
        <span :class="{small: feedSpan[index]}">{{ item.negFeedBackName }}</span>
      </div>
      <div 
        v-if="more && !isOneLine"
        class="product-card__feed-grey"
      ></div>
    </div>
    <div 
      v-show="more && !isOneLine"
      class="product-card__feed-more"
    >
      <i
        class="iconfont icon-forward-down20px"
        @click.stop="showMore"
      ></i>
    </div>
    <div 
      v-if="similarShow"
      class="product-card__feed-similar"
      :class="{more: more && !isOneLine, oneline: isOneLine}"
      @click.stop="openSimilar"
    >
      <div 
        class="product-card__feed-similar-btn"
        :class="{uplowStructure: uplowStructure, oneline: isOneLine}"
      >
        <i class="suiiconfont sui_icon_findsimilar_18px"></i>
        <span>{{ language.SHEIN_KEY_PWA_16789 }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import { abtservice } from 'public/src/services/abt'
import { exposeFeedback, clickFeedback, clickFindSimilarOnFeedback, exposeFindSimilarByFeeback } from '../analysis'
const overflowScrollReg = /scroll|auto|overlay/i

function isElement(node) {
  const ELEMENT_NODE_TYPE = 1
  return node.tagName !== 'HTML' && node.tagName !== 'BODY' && node.nodeType === ELEMENT_NODE_TYPE
}

function getScrollParent(el, root) {
  let node = el

  while (node && node !== root && isElement(node)) {
    const { overflowY } = window.getComputedStyle(node)
    if (overflowScrollReg.test(overflowY)) {
      return node
    }
    node = node.parentNode
  }

  return root
}

export default {
  props: {
    showFeedback: {
      type: Boolean,
      default: false
    },
    feedback: {
      type: Object,
      default: () => ({})
    },
    curData: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    scrollTimers: {
      type: Number,
      default: 0
    },
    imgRatio: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      feedSpan: [],
      showText: false,
      more: false,
      abtest: ''
    }
  },
  computed: {
    list () {
      return this.feedback?.negFeedbackShow == 1 && this.feedback?.negFeedbackInfo || []
    },
    similarShow () {
      return this.feedback?.similarShow
    },
    uplowStructure () {
      return this.feedback?.sceneKey == 'THREE_IN_A_ROW'
    },
    isOneLine () {
      return this.feedback?.sceneKey == 'ONE_IN_A_ROW'
    },
    posKey () {
      return this.config.showFeedbackPoskey || 'RecommendExpandPop'
    },
    isSquareImg() {
      return this.imgRatio === '1-1'
    }
  },
  watch: {
    scrollTimers () {
      this.close(false)
    }
  },
  mounted () {
    this.$nextTick(async () => {
      let feedbackType = []
      const list = this.$refs.feedLi || []
      list.forEach((ele, i) => {
        const span = ele.querySelector('span')
        feedbackType.push((i + 1) + '_' + this.list[i].id)
        this.feedSpan.push(span.offsetHeight > 20)
      })
      // this.more = list.length > 3 && this.similarShow || this.isSquareImg
      this.more = list.length > 3 && this.similarShow
      this.showText = true
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResultForAnalysis({ posKeys: this.posKey })
      this.abtest = abtInfo.sa
      const commonInfo = {
        ...this.curData, 
        feedback: this.feedback, 
        abtest: this.abtest
      }
      if (this.list.length) {
        exposeFeedback({ 
          ...commonInfo,
          feedbackType, 
          goodsLabel: this.config.goodsLabel 
        })
      }
      if (this.similarShow) {
        exposeFindSimilarByFeeback({ ...commonInfo })
      }
    })
    document.addEventListener('click', this.openCheck)
    // document.addEventListener('scroll', this.openCheck)
    getScrollParent(this.$refs.feedContent, document).addEventListener('scroll', this.openCheck)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.openCheck)
    // document.removeEventListener('scroll', this.openCheck)
    getScrollParent(this.$refs.feedContent, document).addEventListener('scroll', this.openCheck)
  },
  methods: {
    clickFeedLi (index, id) {
      clickFeedback({
        ...this.curData,
        feedback: this.feedback,
        index,
        id,
        abtest: this.abtest,
        goodsLabel: this.config.goodsLabel || ''
      })
      this.close(true)
    },
    openCheck () {
      this.close(false)
    },
    close (hide) {
      this.$emit('close', hide)
    },
    showMore () {
      this.more = false
      this.$refs.feedList.scrollTop = 999
    },
    openSimilar () {
      clickFindSimilarOnFeedback({
        ...this.curData,
        feedback: this.feedback,
        abtest: this.abtest
      })
      SimilarInstance.showModal(this.curData, { similarFrom: 'expand' })
      this.close(false)
    }
  }
}
</script>
<style lang="less">
.product-card{
  &__feed {
    position: absolute;
    top: 0;
    left: 0 /* rtl:ignore */;
    right: 0 /* rtl:ignore */;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    .flexbox();
    flex-flow: column;
    z-index: 7; /* stylelint-disable-line */
    justify-content: space-between;
  }
  &__feed-close {
    line-height: 1;
    overflow: hidden;
    flex: 0 0 .6rem;
    i.sui_icon_nav_close_24px.suiiconfont { /* stylelint-disable-line */
      display: block;
      line-height: .6rem;
      width: .6rem;
      .font-dpr(24px);
      color: white;
      font-weight: bold;
      opacity: 0.7;
      float: right;
      text-align: center;
      /* rw:begin */
      .font-dpr(18px);
    }
    &.oneline {
      position: absolute;
      top: 0;
      right: 0;
      z-index: @zindex-hack;
    }
  }
  &__feed-list {
    padding: 0 .213333rem;
    flex: 1 0 3.44rem;
    justify-content: center;
    overflow-y: scroll;
    .flexbox();
    flex-flow: column;
    position: relative;
    &.scroll {
      justify-content: flex-start;
    }
    &.similar {
      flex: 1 0 4.08rem;
    }
    &.square {
      flex: 1 0 2rem;
    }
    &.squareScroll {
      flex: 1 0 2.64rem;
    }
    &.show {
      span {
        visibility: visible;
      }
    }
  }
  &__feed-onelist {
    padding: 0 .64rem;
    margin: .28rem 0 .16rem;
    overflow-y: scroll;
    .flexbox();
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    flex: 1 0 2.093333rem;
    &.show {
      span {
        visibility: visible;
      }
    }
    &.onecolumn {
      justify-content: space-around;
      padding: 0 2rem;
    }
    &.onescroll {
      align-content: flex-start;
    }
    .product-card__feed-li {
      flex: 0 0 3.933333rem;
      height: .68rem;
      justify-content: center;
    }
    .product-card__feed-li span{
      .font-dpr(20px);
    }
    .product-card__feed-li span.small {
      .font-dpr(16px);
      /* 修复历史BUG, 内容换行未居中 */
      text-align: center;
    }
  }
  &__feed-grey {
    position: absolute;
    left: 0.213333rem /* rtl:ignore */;
    right: 0.213333rem /* rtl:ignore */;
    bottom: 0;
    height: .32rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  }
  &__feed-li {
    max-height: .853333rem;
    flex: 0 0 .853333rem;
    padding: 0 .32rem;
    background: white;
    margin-bottom: .213333rem;
    color: #222;
    .flexbox();
    align-items: center;
    justify-content: center;
    /* rw:begin */
    border-radius: .853333rem;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      .line-camp(2);
      .font-dpr(24px);
      visibility: hidden;
      line-height: 1.2;
    }
    span.small {
      .font-dpr(20px);
      /* 修复历史BUG, 内容换行未居中 */
      text-align: center;
    }
  }
  &__feed-more {
    height: .96rem;
    text-align: center;
    line-height: .96rem;
    color: white;
  }
  &__feed-similar {
    flex: 1 0 1rem;
    position: relative;
    padding: 0 .213333rem;
    margin-top: .32rem;
    max-height: 1.1733rem;
    .flexbox();
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    &.more {
      margin-top: 0;
    }
    &.onlysm {
      background: none;
    }
    &.oneline {
      flex: 1 0 .853333rem;
      margin-top: 0;
    }
  }
  &__feed-similar-btn {
    height: 1rem;
    text-align: center;
    color: white;
    .flexbox();
    align-items: center;
    justify-content: center;
    font-weight: bold;
    .font-dpr(24px);
    &.full {
      padding-bottom: .84rem;
    }
    &.uplowStructure {
      flex-direction: column;
    }
    &.oneline {
      height: .853333rem;
    }
    i {
      font-weight: normal;
      .font-dpr(36px);
      color: rgba(255, 255, 255, 0.7);
      transform: rotate(0deg) /* rtl: rotate(90deg) */;
    }
    span {
      margin-left: .213333rem;
    }
  }
}
</style>
