<script lang="jsx">
import { handlePriceHtml } from 'public/src/pages/components/product/item_v3/js/utils.js'
import CamelCasePrice from './CamelCasePrice.vue'
import SuggestedSalePriceContainer from './SuggestedSalePriceContainer.vue'

import ProductCardS3VipSalePriceIcon from './ProductCardS3VipSalePriceIcon.vue'

export default {
  name: 'ProductCardNormalPrice',
  functional: true,
  components: { 
    CamelCasePrice,
    SuggestedSalePriceContainer, 
    ProductCardS3VipSalePriceIcon
  },
  inject: ['constantData', 'config', 'item', 'multiColumn', 'finalPriceAndDiscountInfo'],
  props: {
    // 显示驼峰价格样式
    showCamelPrice: {
      type: Boolean,
      default: false
    },
    // ccc返回的最终腰带
    beltLabel: {
      type: [Object, Boolean],
      default: () => ({})
    },
    supportVueDebug: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props, data, injections }) {
    const { constantData, config, item, multiColumn, finalPriceAndDiscountInfo } = injections || {}

    const { color: finalPriceColor, finalPrice, finalPriceType, priceRight, priceStateCollection, suggestedSalePriceData } = finalPriceAndDiscountInfo || {}

    const { showCamelPrice, beltLabel, supportVueDebug } = props || {}

    const pretreatInfo = item?.pretreatInfo || {}

    const salePrice = item?.salePrice ?? {}

    const { estimatedPriceType } = item.estimatedPriceInfo ?? {}
    const isExclusiveEstimatedPrice = estimatedPriceType === 4

    // 用于调试，最终价格中文显示作为
    let noteText = ''

    // 分人群专区闪购
    const flashZonePriceWithSymbol = item?.flashZonePromotion?.price?.amountWithSymbol || ''

    const { finalABPriceType, hasEstimatedPrice, hasFlashPromotion } = priceStateCollection ?? {}

    // 如果当前商卡命中新到手价的展示样式
    const satisfiedNewStyleEsat = config.showNewStyleEstimated && hasEstimatedPrice && !finalABPriceType

    // 价格的一些自定义样式
    const priceStyle = (() => {
      const {
        salePriceFontSize,
        salePriceColor,
        salePriceFontWeight,
        lineThroughPriceFontSize,
        lineThroughPriceScale
      } = config.style || {}

      const style = {
        salePrice: {},
        retailPrice: {}
      }

      let newStyleEsatFontSize = satisfiedNewStyleEsat ? '0.293333rem' : ''
      let newStyleEsatFontWeight = satisfiedNewStyleEsat ? 400 : ''
      
      salePriceFontSize && (style.salePrice.fontSize = salePriceFontSize)
      newStyleEsatFontSize && (style.salePrice.fontSize = newStyleEsatFontSize)
      salePriceColor && (style.salePrice.color = salePriceColor)
      salePriceFontWeight && (style.salePrice.fontWeight = salePriceFontWeight)
      newStyleEsatFontWeight && (style.salePrice.fontWeight = newStyleEsatFontWeight)
      lineThroughPriceFontSize && (style.retailPrice.fontSize = lineThroughPriceFontSize)
      lineThroughPriceScale && (style.retailPrice.transform = `scale(${lineThroughPriceScale})`)

      return style
    })()

    // 售价Dom
    const salePriceEl = () => {
      const salePriceAmountWithSymbol = finalPrice?.amountWithSymbol
      if (!salePriceAmountWithSymbol) return null

      // 样式类
      const classList = ['prices-info__sale-price']
      const classTypeMap = {
        origin: 'prices-info__sale-price_promo', // 促销色
        brown: 'prices-info__sale-price_sheinclub', // 付费会员色
        darkGold: 'prices-info__sale-price_s3vip' // s3会员色
      }
      if (finalPriceColor) {
        classList.push(classTypeMap[finalPriceColor])
      }

      // 驼峰价格是否支持动态字号
      const camelPriceResizeFontsize = config?.camelPriceResizeFontsize ?? false

      const camelPriceStyle = config?.style?.camelPriceStyle ?? {}

      const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(salePriceAmountWithSymbol, salePrice.amount)

      return (
        <CamelCasePrice
          class={classList}
          show-camel-price={showCamelPrice}
          camel-price-resize-fontsize={camelPriceResizeFontsize}
          currency-symbol-prefix={currencySymbolPrefix}
          number-before-dot={numberBeforeDot}
          number-after-dot={numberAfterDot}
          currency-symbol-suffix={currencySymbolSuffix}
          origin-string={salePriceAmountWithSymbol}
          camel-price-style={camelPriceStyle}
          style={priceStyle.salePrice}
          data-note={supportVueDebug ? noteText : ''}
        />
      )
    }

    // 售价右边Dom
    const salePriceRightEl = () => {
      // s3Vip样式
      if (finalPriceType === 'exclusivePrice' || (finalPriceType === 'estimatedPrice' && isExclusiveEstimatedPrice)) {
        return (
          <ProductCardS3VipSalePriceIcon 
            goodsInfo={item}
            finalPriceAndDiscountInfo={finalPriceAndDiscountInfo}
          />
        )
      }

      return null
    }

    // 价格右侧的原价 合规价相关
    const priceRightEl = () => {
      if (!priceRight?.show) return null

      return (
        <SuggestedSalePriceContainer
          goodsInfo={item}
          priceData={priceRight}
        ></SuggestedSalePriceContainer>
      )
    }

    // 闪购，限时折扣 图标
    const promotionIconListEl = (() => {
      // 展示到手价倒计时不展示time icon
      const { endTime } = item?.estimatedPriceInfo ?? {}

      let couponCountdown = ''
      if (endTime && config.showEstimatedLabelCountdown && !hasFlashPromotion && finalPriceType === 'estimatedPrice') {
        couponCountdown = endTime
      }

      let list = []
      let styleList = ''
      // icon映射 映射路径来源public/src/pages/common/promotion/config.js
      const iconMap = {
        'sui_icon_flashsale_15px': 'sh_m_flashsale_15px_facf19',
        'sui_icon_time_15px': couponCountdown ? '' : 'sh_m_time_15px_fa6338'
      }
      if(
        config.hideIconBeforePrice 
        || 
        (suggestedSalePriceData?.type && (!suggestedSalePriceData?.show30DaysLowestPrice || multiColumn))
      ) {
        return []
      }

      const changeIconFun = (iconName)=> {
        let classNameArr = {}
        // 针对限时折扣icon进行修改
        if (iconName === 'sh_m_time_15px_fa6338') {
          // 新到手价样式需求处理添加（不在预处理中加这个逻辑的原因是，还需要根据条件动态调整icon的大小（否则会有样式问题））， 针对限时icon 改变颜色以及大小
          // 会员价或者会员到手价，限时折扣icon颜色变更
          if (finalPriceColor === 'brown') {
            iconName = 'sh_m_time_15px_873C00'
          }
          // s3会员价限时折扣icon颜色变更
          if (finalPriceColor === 'darkGold') {
            iconName = 'sh_m_time_15px_806208'
          }
  
          // 如果当前命中到手价新样式 并且当前是有到手价的，则改变icon大小
          if(satisfiedNewStyleEsat) {
            classNameArr.width = '13px'
            classNameArr.height = '13px'
          }
        }
        return {
          classNameArr,
          iconName
        }
      }
      
      // web前端预处理匹配promotionInfo
      pretreatInfo.mixPromotionInfo?.forEach(i => {
        if (!iconMap[i.icon]) return
        // app专属图标无需展示
        if (+i.typeId !== 8) {
          if (+i.typeId === 10) {
            if (beltLabel?.component == 'flash_purchase_belt') return
            // 闪电标（1: 普通闪购, 4: 分人群定价专区闪购, 5: 不分人群定价专区闪购）
            if (([1, 5].includes(+i.flash_type) || +i.flash_type === 4 && flashZonePriceWithSymbol) && !config.hideFlashSaleIcon) {
              list.push(iconMap[i.icon])
            }
          } else if (+i.typeId === 3) {
            if (!config.hideLimitedTimeIcon) {
              const { classNameArr, iconName } = changeIconFun(iconMap[i.icon])
              styleList = classNameArr
              list.push(iconName)
            }
          } else {
            const { classNameArr, iconName } = changeIconFun(iconMap[i.icon])
            styleList = classNameArr
            list.push(iconName)
          }
        }
      })

      if (list?.length) {
        const showIconDom = list.filter(v => v).map((promoIcon, index) => {
          return (
            <i
              key={index}
              class={['sh_m_sui_icon', 'prices-info-icon', promoIcon]}
              style={styleList}
            ></i>
          )
        })
        return showIconDom[0]
      } else {
        return null
      }
    })()

    const GB_cssRight = constantData?.GB_cssRight
    
    // 针对AR站处理价格和icon位置反转
    const arChangeIconStyle = GB_cssRight ? 'flex-direction: row-reverse;' : ''

    const pricesInfoContentClassNames = [
      'prices-info__content',
      priceRight?.show ? 'prices-info__content_row-susa' : ''
    ]

    return (
      <div
        class={[
          'fsp-element',
          'prices-info',
          {
            'prices-info_small-size': multiColumn,
            'prices-info__camel-price': showCamelPrice,
          },
          data.class,
          data.staticClass,
        ]}
        style={[data.style, data.staticStyle]}
      >
        <div class={pricesInfoContentClassNames}>
          <div class="content__sale-price-wrapper" style={arChangeIconStyle}>
            
            {promotionIconListEl}
            {salePriceEl()}

            { salePriceRightEl() }
          </div>
          {priceRightEl()}
        </div>
      </div>
    )
  },
}
</script>

<style lang="less">
.prices-info {
  max-width: 100%;
  &__flash-label {
    .font-dpr(20px);
    color: @sui_color_discount;
    line-height: 0.32rem;
  }
  &__content {
    .flexbox();
    .align-center();
    .font-dpr(28px);

    direction: ltr /* rtl:ignore */;
    flex-wrap: wrap;

    // &_hide {
    //   height: 0.48rem;
    //   overflow: hidden;
    // }
    >span {
      .text-overflow();
    }

    &_row-susa {
      gap: 2px;
      align-items: baseline;
      flex-wrap: nowrap;

      .prices-info__suggested-sale-price {
        flex: 1;
        line-height: 14px;
        min-width: 0;
      }
    }

    .content {
      &__sale-price-wrapper {
        .flexbox();
        .align-center();
        min-width: 0;
      }
    }
  }

  >span {
    .text-overflow();
    &:first-of-type {
      flex-shrink: 0;
    }
  }
  [class*="iconfont"] {
    margin-right: 0.0533rem;
  }
  .prices-info-icon {
    margin-right: 0.0533rem;
  }
  .sui_icon_flashsale_15px {
    .font-dpr(30px);
    color: @sui_color_flash;
    height: 0.5333rem;
    line-height: 0.5333rem;
  }
  .sui_icon_time_15px {
    .font-dpr(30px);
    color: @sui_color_discount;
    height: 0.5333rem;
    line-height: 0.5333rem;
  }
  &_small-size {
    .font-dpr(24px);
    line-height: 0.3733rem;
    .sui_icon_flashsale_15px, .sui_icon_time_15px  {
      height: 0.3733rem;
      line-height: 0.3733rem;
    }
  }
  &__unknown-price {
    .font-dpr(28px);
    display: block;
    width: 100%;
    color: #d53333;
    font-weight: bold;
  }
  &__sale-price {
    color: var(--product-card__sale-price-color, #222);
    font-weight: bold;

    &_promo {
      color: @sui_color_discount;
    }
    &_sheinclub {
      color: @sui_color_club_rosegold_dark1;
    }
    &_s3vip {
      color: #806208;
    }
  }
  &__origin-price {
    transform-origin: 0 0; // 为了scale缩放时对齐
    margin-left: 0.1067rem;
    align-self: flex-end;

    text-decoration: line-through;
    font-size: .266rem;
    color: @sui_color_gray_light1; 
  }
  .product-card__discount {
    margin-left: 0.1067rem;
  }
  &__sheinclub-right {
    position: absolute;
    margin: 0;
  }
}
</style>
