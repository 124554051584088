<template>
  <div 
    ref="carouselContainer"
    class="carousel-container"
    :style="containerStyle"
  >
    <swiper-container
      ref="swiperContainer"
      class="product-card__main-img-carousel"
      :dir="GB_cssRight ? 'rtl' : 'ltr'"
      pagination="false"
      init="false"
      :loop="carouselConfig.loop"
      :speed="carouselConfig.speed"
    >
      <swiper-slide
        v-for="(imgSrc, index) in imgList"
        :key="imgSrc + index"
      >
        <CropImageContainer
          :class="className"
          ignoreMask
          :isSupportCropImage="isSupportCropImage"
          :imgSrc="imgSrc"
          :fixedRatio="cropRate ? cropRate : (autoCropImage ? '' : '3-4')"
          :transformImgProps="transformImgProps(index)"
          :imgThumb="imgThumb"
          @imgLoadSucc="e => $emit('imgLoadSucc', e)"
          @imgLoadFail="e => $emit('imgLoadFail', e)"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
import { CropImageContainer, containerRatio } from 'public/src/pages/components/CropImageContainer/index.js'

typeof window !== 'undefined' && register()

export default {
  name: 'ProductCardimgCarousel',
  components: {
    CropImageContainer
  },
  inject: ['item', 'config', 'getIsSupportCropImage', 'constantData'],
  props: {
    mainImg: {
      type: String,
      default: ''
    },
    className: {
      type: Array,
      default: () => []
    },
    // @example '1-1'
    cropRate: {
      type: String,
      default: ''
    },
    compSrc: {
      type: String,
      default: ''
    },
    isUseOriginUrl: {
      type: Boolean,
      default: false
    },
    imgDesignWidth: {
      type: [String, Number],
      default: ''
    },
    autoCropImage: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      IntersectionObserverInstance: null,
      imgList: [],
      imgThumb: '',
    }
  },
  computed: {
    carouselConfig() {
      const { loop, speed, delay, disableOnInteraction } = this.config.mainImgCarouselConfig || {}
      const config = {
        speed: speed ?? 300,
        loop: loop ?? true,
      }
      if (delay > 0) {
        config.autoplay = {
          delay,
          disableOnInteraction: disableOnInteraction ?? true,
        }
      }
      return config
    },
    containerStyle() {
      return {
        paddingBottom: `calc(${containerRatio(this.fixedRatio, this.imgSrc).toFixed(2)} * 100%)`,
      }
    },
    transformImgProps() {
      return (index) => {
        const goodsName = this.item?.goods_name
        const firstImgClassName = index === 0 ? `fsp-element  ${this.compSrc ? 'fsp__' + this.compSrc : ''}` : ''

        return {
          imgClassName: `${firstImgClassName} product-card__main-img`,
          loadImg: this.constantData?.LAZY_IMG_SOLID_COLOR || '',
          isFirstPage: this.isUseOriginUrl,
          imgDesignWidth: this.imgDesignWidth,
          alt: goodsName,
          fetchpriority: 'high',
        }
      }
    }
  },
  created() {
    const { detail_image } = this.item || {}
    const imgList = []
    if (this.mainImg) {
      imgList.push(this.mainImg)
    }

    if (detail_image?.length) {
      imgList.push(...detail_image)
    }

    this.GB_cssRight = this.constantData?.GB_cssRight
    this.imgList = imgList
    this.imgThumb = this.item?._imgThumb || ''
    this.isSupportCropImage = this.getIsSupportCropImage && this.getIsSupportCropImage()
    this.fixedRatio = this.cropRate ? this.cropRate : (this.autoCropImage ? '' : '3-4')
  },
  mounted() {
    this.initIntersectionObserverInstance()
  },
  beforeDestroy() {
    if (this.IntersectionObserverInstance) {
      this.IntersectionObserverInstance?.disconnect?.()
    }
  },
  methods: {
    // 初始化滚动可视化监听实例
    initIntersectionObserverInstance() {
      if (!this.IntersectionObserverInstance) {
        this.IntersectionObserverInstance = new IntersectionObserver((entries) => {
          const intersectionRatio = entries[0].intersectionRatio
          
          if (intersectionRatio > 0) {
            const swiperContainer = this.$refs.swiperContainer

            // 注释自动轮播
            if (this.carouselConfig.autoplay && this.imgList.length > 1) {
              Object.assign(swiperContainer, {
                autoplay: this.carouselConfig.autoplay,
                modules: [Autoplay]
              }) 
            }
        
            this.$refs.swiperContainer.initialize()
            const observeOnce = this.config.mainImgCarouselConfig?.observeOnce
            if (observeOnce === undefined || observeOnce === true) {
              this.IntersectionObserverInstance?.disconnect?.() // 断开之前的监听
              this.IntersectionObserverInstance = null
            }
            this.config.mainImgCarouselConfig?.onSwiperReady?.(swiperContainer)
          }
        }, {
          thresholds: [0, 1]
        })
      }

      this.IntersectionObserverInstance.observe(this.$refs.carouselContainer)
    },
  }
}
</script>

<style lang="less" scoped>
.carousel-container {
  width: 100%;
  height: 0;
  overflow: hidden;
}

.product-card__main-img-carousel {
  z-index: 0;
}

</style>

